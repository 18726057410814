import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/named
import Button, { ButtonProps as DefaultButtonProps } from '@mui/material/Button';
// import { twMerge } from 'tailwind-merge';
import { styled } from '@mui/material';
import { twMerge } from 'tailwind-merge';
import {
  BUTTON_SIZE_TYPE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  TRANSPARENT,
} from 'src/components/WidgetMaker/utils/buttonConstant';
import buttonAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ButtonAnimationStyles.module.scss';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { getBackgroundColorForOutline } from './utils';

type CustomVariant =
  | 'primary'
  | 'secondary'
  | 'secondary-2'
  | 'secondaryFilled'
  | 'secondaryFilled-2'
  | 'tertiary';

interface CustomButtonProps {
  customVariant: CustomVariant;
  customBgColor?: string;
}

const CustomButton = styled(Button)<CustomButtonProps & DefaultButtonProps>(
  ({ customVariant, customBgColor }: any) => ({
    transition: 'all 250ms ',
    transitionDuration: '250ms',
    transitionTimingFunction: 'ease-in',
    fontFamily: 'inherit',
    // minWidth: 'unset',

    '&:hover': {
      opacity: 0.96,
      backgroundColor:
        customBgColor ||
        defaultColors[Object.keys(defaultColors).find((key) => customVariant === key)]
          ?.backgroundColor,
    },
  })
);

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  image?: any;
  imageDirection?: 'left' | 'right';
  size?: 'small' | 'medium' | 'large';
  variant?: CustomVariant;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  showShadow?: boolean;
  removeHoverBg?: boolean;
  onMouseUp?: any;
  onMouseDown?: any;
  btnType?: string;
  buttonData?: any;
  selectedButtonData?: any;
  textualAlignment?: string;
  textWrapperClass?: string;
  getHoverColor?: (x: any) => void;
  disableHoverAnimation?: boolean;
  useReversedColors?: boolean;
  removeAnimationFromMobile?: boolean;
}

const defaultStyleAttributes: React.CSSProperties = {
  textTransform: 'none',
  padding: 0,
};

const defaultColors = {
  primary: {
    backgroundColor: '#2B74D8',
    color: '#ffffff',
  },
  secondary: {
    backgroundColor: '#ffffff',
    color: '#2B74D8',
    border: '1px solid #111C361F',
  },
  'secondary-2': {
    backgroundColor: '#ffffff',
    color: '#111C36',
    border: '1px solid #111C361F',
  },
  secondaryFilled: {
    backgroundColor: '#111C360F',
    color: '#2B74D8',
    border: '1px solid #111C361F',
  },
  'secondaryFilled-2': {
    backgroundColor: '#111C360F',
    color: '#111c36',
    border: '1px solid #111C361F',
  },
  'tertiary:': {
    textColor: '#2B74D8',
  },
};

const defaultGeometry: Record<string, React.CSSProperties> = {
  small: {
    fontSize: 10,
    padding: '4px 18px',
  },
  medium: {
    fontSize: 14,
    padding: '8px 24px',
  },
  large: {
    fontSize: 18,
    padding: '12px 30px',
  },
};

export default function CustomizedButton(props: Props) {
  const {
    imageDirection = 'left',
    size = BUTTON_SIZE_TYPE.MEDIUM,
    variant = 'primary',
    removeHoverBg,
    btnType,
    buttonData,
    selectedButtonData,
    textualAlignment = '',
    getHoverColor,
    // Replaces original colors scheme with the reverse ones that show up during animation
    useReversedColors,
    // removeAnimationFromMobile = false,
  } = props;
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();
  // const isMobile = isMobileDevice() || (isBuilder && previewDevice === 'mobile');
  const stripButtonDefaults = variant === BUTTON_VARIANT.TERTIARY;
  const [isHover, setIsHover] = useState(false);
  const customBtnRef = useRef<HTMLButtonElement>(null);
  const colorFromButtonData = buttonData?.btnStyleConfig?.textColor;
  const bgColorFromButtonData =
    btnType === BUTTON_TYPE.SOLID
      ? buttonData?.btnStyleConfig?.backgroundColor
      : btnType === BUTTON_TYPE.OUTLINE
        ? TRANSPARENT
        : '';
  const borderColorFromButtonData =
    btnType === BUTTON_TYPE.OUTLINE ? buttonData?.btnStyleConfig?.backgroundColor : '';
  const btnColor = colorFromButtonData ? colorFromButtonData : props?.color;
  const btnBgColor = bgColorFromButtonData
    ? bgColorFromButtonData
    : props?.backgroundColor;
  const borderColor = borderColorFromButtonData
    ? borderColorFromButtonData
    : props?.borderColor;
  const animationClass = buttonData?.buttonAnimationConfig?.classKey;
  const hideOverlfowBasedOnAnimation = animationClass
    ? ![
        'widgetBtnShadowWrapper',
        'widgetProductBtnShadowWrapper',
        'widgetBtnUnderlineRightWrapper',
        'widgetBtnUnderlineRightWrapper2',
      ]?.includes(animationClass)
    : false;
  const isMoveAnimation =
    animationClass &&
    btnType !== BUTTON_TYPE.SOLID &&
    animationClass?.toLowerCase()?.includes('move');

  const reversedColors = getBackgroundColorForOutline(btnColor);
  const isReverseColorsEligible = [BUTTON_TYPE.OUTLINE, BUTTON_TYPE.SOLID].includes(
    btnType
  );

  useEffect(() => {
    if (getHoverColor) {
      getHoverColor(isHover && btnType === BUTTON_TYPE.OUTLINE ? reversedColors : null);
    }
  }, [isHover]);

  useEffect(() => {
    if (btnType === BUTTON_TYPE.OUTLINE && customBtnRef?.current) {
      const updatedRadius = globalStyle?.buttons?.style?.borderRadius?.replace(
        '!important',
        ''
      );
      const backgroundColor = reversedColors.background;
      customBtnRef.current?.style.setProperty('--before-bg', backgroundColor);
      if (globalStyle?.buttons?.isEnabled) {
        customBtnRef.current?.style.setProperty('--border-radius', updatedRadius);
      } else {
        customBtnRef.current?.style.setProperty('--border-radius', '0px');
      }
    } else if (btnType === BUTTON_TYPE.TEXTUAL && customBtnRef?.current) {
      customBtnRef.current?.style.setProperty('--textual-bg', btnColor);
    }
  }, [btnType, btnColor, globalStyle?.buttons]);

  const dropShadow = (() => {
    if (!props.showShadow) {
      return '';
    }

    switch (variant) {
      case BUTTON_VARIANT.SECONDARY:
      case BUTTON_VARIANT.SECONDARY2:
        return ' !tw-shadow-[0px_2px_6px_0px_#0000001F] ';
      case BUTTON_VARIANT.SECONDARYFILLED:
      case BUTTON_VARIANT.SECONDARYFILLED2:
        return ' !tw-shadow-[0px_2px_6px_0px_#0000001F] ';
    }
  })();

  const getCustomBgColor = () => {
    if (removeHoverBg) {
      return TRANSPARENT;
    }
    return (
      props.className?.split?.(' tw-bg-[')?.[1]?.split?.(']')?.[0] ||
      props.className?.split?.(' !tw-bg-[')?.[1]?.split?.(']')?.[0] ||
      btnBgColor
    );
  };

  return (
    <CustomButton
      customVariant={variant}
      customBgColor={getCustomBgColor()}
      onClick={(event) => {
        event.stopPropagation();
        props?.onClick && props?.onClick(event);
      }}
      ref={customBtnRef}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      disabled={props.disabled}
      onTouchStart={!props.disableHoverAnimation ? () => setIsHover(true) : undefined}
      onTouchEnd={!props.disableHoverAnimation ? () => setIsHover(false) : null}
      onMouseEnter={!props.disableHoverAnimation ? () => setIsHover(true) : null}
      onMouseLeave={!props.disableHoverAnimation ? () => setIsHover(false) : null}
      sx={{
        ...defaultStyleAttributes,
        ...defaultColors[variant],
        ...(!stripButtonDefaults ? defaultGeometry[size] : {}),
      }}
      className={twMerge(
        'tw-relative tw-flex tw-items-center tw-justify-center tw-gap-[10px] tw-font-semibold tw-leading-[1] tw-tracking-normal',
        imageDirection === 'left' ? '!tw-flex-row ' : '!tw-flex-row-reverse ',
        dropShadow,
        props.className,
        selectedButtonData?.animationColor ? selectedButtonData?.animationColor : '',
        selectedButtonData?.customClasses ? selectedButtonData.customClasses : '',
        !props.disableHoverAnimation && animationClass && !props.disabled
          ? buttonAnimationStyle[animationClass]
          : '',
        hideOverlfowBasedOnAnimation && 'tw-overflow-hidden'
      )}
      style={{
        borderColor: !useReversedColors
          ? borderColor
          : isReverseColorsEligible
            ? reversedColors.text
            : borderColor,
        backgroundColor:
          variant !== BUTTON_VARIANT.TERTIARY
            ? !useReversedColors
              ? btnBgColor
              : isReverseColorsEligible
                ? reversedColors.background
                : btnBgColor
            : '',
        ...(variant === BUTTON_VARIANT.TERTIARY && textualAlignment
          ? { justifyContent: textualAlignment }
          : {}),
      }}
    >
      {props.image ? (
        typeof props.image === 'object' ? (
          props.image
        ) : (
          <img src={props.image} />
        )
      ) : null}
      {props.children ? (
        <p
          style={{
            color:
              (isReverseColorsEligible && useReversedColors && !isMoveAnimation) ||
              (!props.disableHoverAnimation &&
                isHover &&
                btnType === BUTTON_TYPE.OUTLINE &&
                !isMoveAnimation)
                ? reversedColors.text
                : btnColor,
          }}
          className={twMerge('!tw-z-[2] !tw-m-0', props.textWrapperClass)}
        >
          {props.children}
        </p>
      ) : null}
    </CustomButton>
  );
}
