/**
 * Not accepting orders because
 * store is closed for now information component
 */
import { getRoute, PATH } from '@/utils/routes';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotAcceptingOrdersModalVisibility } from '../../../../redux/actions';
import { roundnessCalculator } from '../../../../components/DesignSystem/AtomicComponents/util';
import { getThemeColor } from 'src/utils/getThemeColor';

const NotAcceptingOrdersPopUp = () => {
  const router = useRouter();
  const primaryColor = getThemeColor() || '#EA5151';

  const dispatch = useDispatch();
  const { theme, store_info, store, notAcceptingOrdersPopUp } = useSelector((state) => ({
    theme: state.storeReducer.store.theme,
    store_info: state.storeReducer.store.store_info,
    store: state.storeReducer.store,
    notAcceptingOrdersPopUp: state.errorReducer.common.notAcceptingOrdersPopUp,
  }));

  useEffect(() => {
    if (notAcceptingOrdersPopUp) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    }
  }, [notAcceptingOrdersPopUp]);

  const onClickExploreBtn = (e) => {
    onClose();
    router.push(getRoute(PATH.PRODUCT, store_info?.domain));
  };

  const onClickCallUsBtn = (e) => {
    onClose();
    window.open(`tel:${store?.display_phone || store?.owner?.phone}`, '_self');
    router.push(getRoute(PATH.PRODUCT, store_info?.domain));
  };

  const onClose = () => {
    dispatch(setNotAcceptingOrdersModalVisibility(false));
  };

  return (
    notAcceptingOrdersPopUp && (
      <div className="flex justify-center items-center fixed left-0 bottom-0 top-0 right-0 w-100 bg-black-50 z-999">
        <div className="pa3 bg-white br3 z1">
          <h3 className="ph2">Note</h3>
          <p className="f7 ph2">We are not accepting online orders right now</p>
          <p className="f7 ph2">You can explore our store or call us for any queries.</p>
          <div className="flex pt4 ph2">
            <button
              style={{
                borderColor: primaryColor,
                color: primaryColor,
                borderRadius: theme ? `${roundnessCalculator(theme?.roundness)}` : '8px',
              }}
              className="ba pv3 bg-white flex-auto mr3"
              onClick={onClickCallUsBtn}
            >
              Call Us
            </button>
            <button
              style={{
                backgroundColor: primaryColor,
                borderRadius: theme ? `${roundnessCalculator(theme?.roundness)}` : '8px',
              }}
              className="white bn pv3 flex-auto"
              onClick={onClickExploreBtn}
            >
              Explore
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default NotAcceptingOrdersPopUp;
