import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deviceWidth } from '@/utils/deviceWidth';
import { isDeliveryPincodeBased } from '@/utils/isDeliveryPincodeBased';
import { getDiscountPercentage } from '@/utils/getDiscountPercentage';
import { mutateItemObjectWithCartData } from '@/utils/mutateItemObjectWithCartData';
import { mutateVariantItemWithCartData } from '@/utils/mutateVariantItemWithCartData';
import {
  editCartProductPersonalization,
  fetchCatalogItemById,
  pushEventsLog,
  saveCart,
  setUnavailableQuantityAlertState,
  setVariantModalVisibility,
} from '../../../redux/actions';
import CartButton from '../AtomicComponents/CartButton';
import { CURRENCY_SYMBOL } from 'src/config';
import {
  Item,
  ItemInfo,
  ItemImage,
  ItemDetails,
  ItemName,
  ItemSub,
  ItemPrice,
  DiscountedPrice,
  ActualPrice,
  ItemDiscount,
  ItemVariantName,
  ItemTotalValue,
  CartButtonItemValueContainer,
  DeliveryCharges,
  ItemContainer,
  ItemRemarksWrapper,
} from './styles';
import CartRemoveCta from '@/components/CartRemoveCta/CartRemoveCta';
import CartError from '@/components/CartError/CartError';
import { MAX_MOBILE_WIDTH } from '@/utils/constants';
import { useCartHandlers } from '../../../hooks/useCartHandler';
import { IS_CLIENT } from '@/utils/checkRenderEnv';
import ItemPersonalizationDetails from './ItemPersonalizationDetails';
import ItemSchedulingInfo from 'src/components/ItemSchedulingInfo';

const CartItem = (props) => {
  const {
    name = props.data.name || props.data.item_name,
    price = props.data.price || props.data.actual_price,
    discounted_price,
    quantity,
  } = props.data;

  const [productItem, setProductItem] = useState(props.data);
  const [viewCompleteRemarks, setViewCompleteRemarks] = useState(false);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [personalizationsData, setPersonalizationsData] = useState(null);

  const [isItemUpdating, setIsItemUpdating] = useState(false);

  const { cartData, storeData, userData, setCartLoader } = useSelector((state) => ({
    storeData: state.storeReducer.store,
    cartData: state.cartReducer,
    userData: state.userReducer.data,
    setCartLoader: state.commonReducer.setCartLoader,
  }));

  const dispatch = useDispatch();

  const {
    handleSetProductCustomizationCount,
    handleIncreamentClick,
    handleDecreamentClick,
    handleSetProductData,
  } = useCartHandlers();

  useEffect(() => {
    if (!setCartLoader && isItemUpdating) {
      setIsItemUpdating(false);
    }
  }, [setCartLoader]);

  useEffect(() => {
    let percent = 0;
    const _tempItem = { ...props.data };
    let personalizations = [];
    /** If this is a variant product */
    if (props.variant) {
      _tempItem.discounted_price = props?.variant?.discounted_price;
      _tempItem.price = props?.variant?.price;
      _tempItem.quantity = props?.variant?.quantity || 0;
      _tempItem.available_quantity = props?.variant?.available_quantity || 0;
      _tempItem.thumbnail_url = props.variant?.thumbnail_url;
      personalizations = props?.variant?.personalization_info?.personalizations ?? [];

      /** If variant has image */
      if (props.variant.images) {
        _tempItem.image_url = props.variant?.images?.[0]?.image_url;
      }

      percent = getDiscountPercentage(
        props.variant.price,
        props.variant.discounted_price
      );
    } else {
      personalizations = props.data?.personalization_info?.personalizations ?? [];
      _tempItem.quantity = props?.data?.quantity || 0;
      _tempItem.available_quantity = props?.data?.available_quantity || 0;
      percent = getDiscountPercentage(price, discounted_price);
    }

    if (personalizations && personalizations?.length) {
      dispatch(
        fetchCatalogItemById(
          { store_id: storeData?.store_id, item_id: props.data?.item_id },
          true,
          (data) => {
            _tempItem['available'] = data.available;
            _tempItem['variants'] = data.variants;
            _tempItem['product_personalization'] = data.product_personalization;
            _tempItem['variants_count'] = data.variants_count;
            dispatch(setVariantModalVisibility(false));
          }
        )
      );
    }

    /** set the discount percentage & product item */
    setDiscountPercent(percent ? percent : 0);
    setProductItem(_tempItem);
    setPersonalizationsData(personalizations);
  }, [cartData.items]);

  useEffect(() => {
    if (productItem?.product_personalization) {
      handleSetProductData(productItem);
    }
  }, [productItem]);

  const handleAddRemoveItem = (type, count = null) => {
    dispatch(
      pushEventsLog({
        event_name: 'Cx_ATC',
        data: {
          store_id: storeData?.store_id,
          domain: storeData?.store_info?.domain,
          page: window.location.href,
          device: deviceWidth <= MAX_MOBILE_WIDTH ? 'Mobile' : 'Desktop',
          cx_number: userData?.phone,
        },
      })
    );

    if (props.variant) {
      const newCart = mutateVariantItemWithCartData(
        type,
        props.data,
        props.variant,
        cartData,
        null,
        null,
        null,
        count
      );
      dispatch(saveCart(newCart));
      return;
    }

    const newState = mutateItemObjectWithCartData(
      type,
      props.data,
      cartData,
      null,
      null,
      null,
      count
    );
    dispatch(saveCart(newState));
  };

  function pincodeBasedDeliveryServicableCheck() {
    if (isDeliveryPincodeBased() && userData?.user_id) {
      if (cartData?.is_store_unserviceable) return false;
      return productItem?.is_unserviceable;
    }
    return false;
  }

  function showDeliveryCharges(item) {
    if (isDeliveryPincodeBased() && item?.delivery_charges > 0) return true;
    return false;
  }

  const toggleCompleteRemarks = () => setViewCompleteRemarks((val) => !val);

  const getTruncatedText = (remark) => {
    const TRUNCATED_CHAR_LIMIT = 40;
    if (remark?.length < TRUNCATED_CHAR_LIMIT) return remark;
    else
      return !viewCompleteRemarks
        ? `${remark?.substr(0, TRUNCATED_CHAR_LIMIT)}...`
        : remark;
  };

  const getAdditionalRemarkText = () =>
    props.variant?.additional_remark || productItem?.additional_remark;

  const isItemAdditionDisabled = () => {
    if (productItem?.managed_inventory)
      return !(productItem?.quantity < productItem?.available_quantity);
    else return false;
  };

  const handleEditClick = (data, idx) => {
    handleSetProductCustomizationCount(productItem);
    handleSetProductData(productItem);
    IS_CLIENT && localStorage.setItem('activeVariantId', props?.variant?.variant_id);
    const itemData = props?.variant || props?.data || {};
    dispatch(
      editCartProductPersonalization({
        personalizedProductData: {
          ...itemData,
          index: idx,
        },
        editableData: { images: data?.images, texts: data?.texts },
      })
    );
  };

  const updateCartItemForPersonalisedProduct = (type) => {
    handleSetProductCustomizationCount(productItem);
    handleSetProductData(productItem);
    if (productItem?.variants_count) {
      IS_CLIENT && localStorage.setItem('activeVariantId', props?.variant?.variant_id);
    }
    switch (type) {
      case 'inc':
        handleIncreamentClick(type, productItem);
        break;
      case 'dec':
        handleDecreamentClick(type, productItem);
        break;
      // this case would occur on personalised item deletion
      case 'custom':
        handleAddRemoveItem(type, 0);
      default:
        break;
    }
  };

  const handleCartItemUpdate = (type, count = null) => {
    if (!productItem?.product_personalization) {
      handleAddRemoveItem(type, count);
      return;
    }
    updateCartItemForPersonalisedProduct(type, count);
  };

  const handleItemChange = (type, count = null, reset = null) => {
    if (
      count &&
      productItem?.managed_inventory &&
      productItem?.available_quantity < count
    ) {
      dispatch(
        setUnavailableQuantityAlertState({
          show: true,
          availableQuantity: productItem?.available_quantity,
          onConfirm: () => {
            setIsItemUpdating(true);
            handleCartItemUpdate(type, productItem?.available_quantity);
          },
          onCancel: reset,
        })
      );
    } else {
      setIsItemUpdating(true);
      handleCartItemUpdate(type, count);
    }
  };

  return (
    <ItemContainer className="tw-relative !tw-border-none">
      {pincodeBasedDeliveryServicableCheck() && (
        <CartError
          text="This item can’t be delivered to the selected address"
          iconWidth={14}
          iconHeight={14}
          mri={8}
          width={deviceWidth < 767 ? '100vw' : '24.48vw'}
          fontSize={12}
          mt={12}
          mb={12}
        />
      )}
      <Item imageUrl={productItem?.image_url} key={props?.key}>
        {storeData?.premium === 1 && productItem?.thumbnail_url && (
          <ItemImage className="tw-flex-shrink-0">
            <img src={productItem?.thumbnail_url} />
          </ItemImage>
        )}
        <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-[16px] md:tw-flex-row md:tw-gap-0">
          <ItemInfo>
            <ItemDetails>
              <ItemName>{name}</ItemName>
              {props?.variant && (
                <ItemVariantName>{props.variant.variant_name}</ItemVariantName>
              )}
              <ItemSub>
                <ItemPrice>
                  <DiscountedPrice>{`${CURRENCY_SYMBOL}${productItem?.discounted_price}`}</DiscountedPrice>
                  {discountPercent !== 0 && (
                    <ActualPrice>{`${CURRENCY_SYMBOL}${
                      productItem?.price || productItem?.actual_price
                    }`}</ActualPrice>
                  )}
                </ItemPrice>
                {discountPercent !== 0 && (
                  <ItemDiscount>{`${discountPercent}`}% OFF</ItemDiscount>
                )}
              </ItemSub>

              {showDeliveryCharges(productItem) && (
                <DeliveryCharges>
                  Delivery: {`${CURRENCY_SYMBOL}${productItem?.delivery_charges}`}
                </DeliveryCharges>
              )}
            </ItemDetails>
          </ItemInfo>
          <CartButtonItemValueContainer className="tw-flex-1 !tw-justify-between md:!tw-justify-end">
            <CartButton
              isUpdating={isItemUpdating}
              customClasses="tw-h-fit !tw-relative !tw-w-[132px] md:!tw-w-fit"
              allowItemDeletion={true}
              allowCustomInput={!productItem?.product_personalization}
              disableAddition={isItemAdditionDisabled()}
              quantity={props.variant ? props.variant.quantity : quantity}
              onChange={handleItemChange}
              fontSize={14}
            />
            <ItemTotalValue className="tw-w-[80px]">
              {CURRENCY_SYMBOL}
              {props.variant ? props?.variant?.amount : productItem?.amount}
            </ItemTotalValue>
          </CartButtonItemValueContainer>
        </div>
      </Item>
      {!!getAdditionalRemarkText() && (
        <ItemRemarksWrapper>
          <p className="heading">Additional Remarks: </p>
          <p className="cp" onClick={toggleCompleteRemarks}>
            "{getTruncatedText(getAdditionalRemarkText())}"
          </p>
        </ItemRemarksWrapper>
      )}
      {pincodeBasedDeliveryServicableCheck() && (
        <CartRemoveCta item={productItem} mt={8} />
      )}
      <ItemSchedulingInfo schedulingInfo={props?.data?.schedule_info} />
      <ItemPersonalizationDetails
        storeData={storeData}
        personalizationsData={personalizationsData}
        handleEditClick={handleEditClick}
      />
    </ItemContainer>
  );
};

export default CartItem;
