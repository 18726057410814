import React from 'react';
import { getCardConfigurations } from '../CardFrameCollection/utils';

export const MAX_SECTION_WIDTH = 1600;
export const MAX_CARD_HEIGHT = 500;
export const MAX_CARD_WIDTH = 500;
export const MIN_CARD_WIDTH = 120; // 170; // 100;
export const MIN_CARD_HEIGHT = 120; // 170; // 100;
export const MAX_NUM_ROWS_DESKTOP = 2; // 170; // 100;
export const MAX_NUM_ROWS_MOBILE_ONE_COL = 4;
export const MAX_NUM_ROWS_MOBILE_TWO_COL = 6;
export const INITIAL_SCROLL_PERCENTAGE = 20;
export const MULTI_COLUMN_MIN_WIDTH = 150;
export const MULTI_COLUMN_MIN_WIDTH_MOBILE = 100;
export const MIN_LOGO_SLIDER_CARD_WIDTH = 50;
export const MAX_LOGO_SLIDER_CARD_WIDTH = 160;
export const MAX_LOGO_SLIDER_HEIGHT = 84;
export const MIN_LOGO_SLIDER_HEIGHT = 50;

// Returns number of columns
export function getContainerTracks({
  isLayoutCarousel,
  itemCount,
  isMobile,
  configData,
}) {
  const { numColumnsDesktop, numColumnsMobile } = configData || {};

  let numColumns = isMobile ? numColumnsMobile : numColumnsDesktop;
  if (itemCount < numColumns) {
    numColumns = itemCount;
  }
  const numRowsForGrid = isMobile
    ? numColumns == 1
      ? MAX_NUM_ROWS_MOBILE_ONE_COL
      : MAX_NUM_ROWS_MOBILE_TWO_COL
    : MAX_NUM_ROWS_DESKTOP;
  const numRows = isLayoutCarousel ? 1 : numRowsForGrid; // for grid -> 'auto', for carousel -> 1;

  return { numColumns, numRows };
}

function getCardMinMaxWidth(type, isMobile, forType) {
  switch (type) {
    case 'icon':
    case 'image':
      return forType === 'max'
        ? MAX_CARD_WIDTH
        : isMobile
          ? MULTI_COLUMN_MIN_WIDTH_MOBILE
          : MULTI_COLUMN_MIN_WIDTH;
    case 'logoSlider':
      return forType === 'max' ? MAX_LOGO_SLIDER_CARD_WIDTH : MIN_LOGO_SLIDER_CARD_WIDTH;
    default:
      return forType === 'max' ? MAX_CARD_WIDTH : MIN_CARD_WIDTH;
  }
}

function getMaxHeight(type) {
  switch (type) {
    case 'logoSlider':
      return MAX_LOGO_SLIDER_HEIGHT;
    default:
      return MAX_CARD_HEIGHT;
  }
}

// Width for each column (product item)
export function getOneCellGeometry({
  cardContainerWidthForEachCell,
  containerTracks,
  aspectRatio,
  widthAdditionals,
  type,
  isMobile,
}) {
  let width = cardContainerWidthForEachCell / containerTracks.numColumns;
  if (width > getCardMinMaxWidth(type, isMobile, 'max'))
    width = getCardMinMaxWidth(type, isMobile, 'max');
  if (width < getCardMinMaxWidth(type, isMobile, 'min'))
    width = getCardMinMaxWidth(type, isMobile, 'min');
  // calculate height
  const [widthRatio, heightRatio] = aspectRatio.split(':').map((item) => +item);
  // Reduce width slightly for each card so that an overflowed card is shown slightly to nudge the user to scroll
  width = width + widthAdditionals;
  let height = width * (heightRatio / widthRatio);
  if (height > getMaxHeight(type)) {
    height = getMaxHeight(type);
    width = type !== 'logoSlider' ? height * (widthRatio / heightRatio) : width;
  }

  return {
    width,
    height: height,
  };
}

export function getDisplayLabelStyles(configData) {
  const textAlign = configData.textAlign;
  const styles = {
    alignItems: '',
    width: '100%',
  };
  switch (textAlign) {
    case 'left':
      styles.alignItems = 'flex-start';
      break;
    case 'center':
      styles.alignItems = 'center';
      break;
    default:
      styles.alignItems = 'center';
      break;
  }

  return styles;
}

interface IOnScrollCarousel {
  e: React.UIEvent<HTMLDivElement, UIEvent>;
  setCarouselIndicatorConfigs: any;
  slideOffset?: number;
  widthOffset?: number;
}
export function onCarouselScroll({
  e,
  setCarouselIndicatorConfigs,
  slideOffset = 0,
  widthOffset = 0,
}: IOnScrollCarousel) {
  const sectionViewportWidth = Math.floor(e.currentTarget.clientWidth);
  const sectionScrollWidth = Math.floor(e.currentTarget.scrollWidth);
  const currentScrollPosition = Math.floor(e.currentTarget.scrollLeft);
  const scrollPercent =
    (currentScrollPosition / (sectionScrollWidth - sectionViewportWidth)) * 100;
  // INITIAL_SCROLL_PERCENTAGE move advantage given
  const offsetPosition = scrollPercent * (INITIAL_SCROLL_PERCENTAGE / 100);
  // const currentOverflowSectionIndex = Math.ceil(
  //   (currentScrollPosition + sectionViewportWidth) / sectionViewportWidth
  // );
  const currentOverflowSectionIndex =
    Math.ceil(currentScrollPosition / (slideOffset - widthOffset)) + 1;

  setCarouselIndicatorConfigs((data) => {
    const newScrollPercentage =
      scrollPercent - offsetPosition + INITIAL_SCROLL_PERCENTAGE;
    return {
      ...data,
      direction: newScrollPercentage > data.scrollPercentage ? 'to-right' : 'to-left',
      scrollPercentage: Math.ceil(newScrollPercentage),
      currentOverflowSectionIndex:
        currentOverflowSectionIndex > data.overflowSectionCount
          ? data.overflowSectionCount
          : currentOverflowSectionIndex,
    };
  });
}

export function getArrowMethods(itemsContainerRef, slideOffset) {
  function onNextArrowClick() {
    itemsContainerRef.current?.scrollBy({
      top: 0,
      left: slideOffset,
      behavior: 'smooth',
    });
  }
  function onPrevArrowClick() {
    itemsContainerRef.current?.scrollBy({
      top: 0,
      left: -slideOffset,
      behavior: 'smooth',
    });
  }

  return { onNextArrowClick, onPrevArrowClick };
}

/* No padding is applied when carousel layout is chosen on mobile so scroll is from one screen edge
to another, so a margin is given on first & last element to give initial spacing before scroll starts **/
export const getCardFrameCustomStyle = ({
  isLayoutCarousel,
  isMobile,
  contentHorizontalPadding,
  index,
  arr,
}) => {
  let margin = '';
  if (isLayoutCarousel && isMobile) {
    if (!index) margin = `0px 0px 0px ${contentHorizontalPadding}px`;
    if (index === arr.length - 1) margin = `0px  ${contentHorizontalPadding}px 0px 0px`;
  }
  return { margin };
};

function getColumnGap(isMobile, type, isLayoutTypeCard, listPresentationConfig) {
  if (type === 'icon' || type === 'image') {
    return isMobile
      ? isLayoutTypeCard
        ? 8
        : 16
      : listPresentationConfig?.columnGap >= 0
        ? listPresentationConfig?.columnGap
        : 44;
  } else if (type === 'logoSlider') {
    return isMobile ? (!isLayoutTypeCard ? 16 : 10) : 20;
  }
  return isMobile ? 10 : 20;
}

function getRowGap(
  isMobile,
  type,
  isLayoutTypeCard,
  isTextNameBelow,
  isCollectionNameVisible
) {
  if (type === 'icon' || type === 'image') {
    return !isMobile ? 44 : isLayoutTypeCard ? 8 : 16;
  } else if (type === 'logoSlider') {
    return !isMobile ? 20 : !isLayoutTypeCard ? 20 : 10;
  }

  return !isMobile
    ? isTextNameBelow && isCollectionNameVisible
      ? 40
      : 20
    : isTextNameBelow && isCollectionNameVisible
      ? 36
      : 10;
}

export const getGridGapsForAxes = ({
  isMobile,
  cardFrameConfig,
  listPresentationConfig,
  type,
  isLayoutTypeCard,
}) => {
  const { isTextNameBelow, isCollectionNameVisible } =
    getCardConfigurations(cardFrameConfig);

  return {
    columnGap: getColumnGap(isMobile, type, isLayoutTypeCard, listPresentationConfig),
    rowGap: getRowGap(
      isMobile,
      type,
      isLayoutTypeCard,
      isTextNameBelow,
      isCollectionNameVisible
    ),
  };
};
