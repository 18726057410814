import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCatalogItemById,
  pushEventsLog,
  saveCart,
  saveProductLoaderId,
  // setChooseAndRepeatModalVisibility,
  setSelectedVariantModalVisibility,
  setVariantModalVisibility,
} from 'src/redux/actions';
import {
  AmountActual,
  AmountDiscounted,
  CardContainer,
  CardBody,
  ButtonContainer,
  CardData,
  CardDescription,
  CardDiscount,
  CardImage,
  CardMain,
  CardPrice,
  CardTitle,
  Button,
  CardTags,
  CardTag,
  CardInventory,
  CardDescriptionMock,
} from './Card.styles';
import { checkForLimitedQuantity } from '@/utils/checkForLimitedQuantity';
import { itemQuantityValueComparison } from '@/utils/itemQuantityValueComparison';
import { deviceWidth } from '@/utils/deviceWidth';
import { scrollItemAndRemoveDetails } from '@/utils/scrollItemAndRemoveDetails';
import { getStoreType } from '@/utils/getStoreType';
import { getDiscountPercentage } from '@/utils/getDiscountPercentage';
import { toastNotifyInfo } from '@/components/Common/Toast';
import RatingBox from '../../RatingBox';
import {
  ITEM_ACTIVE_STATUS,
  MAX_MOBILE_WIDTH,
  STORE_CUSTOMIZATION,
} from '@/utils/constants';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import moment from 'moment';
import { useSSRSelector } from '@/redux/ssrStore';
import {
  componentTypeEnums,
  getImageUrlFromCompressedImages,
  getMaxImgSizeForComponent,
} from '@/utils/compressedImagesHelpers';
import { mutateItemObjectWithCartData } from '@/utils/mutateItemObjectWithCartData';
import { Link } from '@/components/Link';
import { useItemClick } from '@/hooks/useItemClick';
import { canShowPDPSystem } from '@/utils/canShowPDPSystem';
import { openProductPersonalizationPopUp, savePDPData } from '@/redux/actions';
import { useCartHandlers } from '@/hooks/useCartHandler';
import OfferAvailableTag from 'src/components/CouponsAndVouchers/OfferAvailableTag';
import { hexToRgba } from '../ModernTheme/utils/common';
import { IS_SERVER } from 'src/utils/checkRenderEnv';

export const Card = (props) => {
  const {
    data = {},
    // productCard = false,
    showCategoryCard = false,
    imageUrl = 'https://picsum.photos/300/300',
    noTags: noTagsFromProps = true,
    noTagsReadState = 'fromProp',
    skeleton,
    skeletonImage,
    cardWidth = undefined,
  } = props;

  const { theme, store_id, services, premium } = useSSRSelector(
    (state) => state.storeReducer.store
  );

  const [onItemClick, getLink] = useItemClick();
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cartReducer);
  const { storeInfo, oosStatus, storeData } = useSSRSelector((state) => {
    const storeData = state.storeReducer.store;
    return {
      storeInfo: storeData,
      storeData,
      oosStatus: storeData?.services?.hide_out_of_stock,
    };
  });

  const { userData, reviewSettings } = useSelector((state) => ({
    userData: state.userReducer,
    reviewSettings: state.catalogReducer.reviewSettings,
  }));
  const [discountPercent, setDiscountPercent] = useState(0);
  const { crrFeatureStatus } = useFeatureLocks();
  const isB2bStore = getStoreType(storeData) === STORE_CUSTOMIZATION.B2B;

  const {
    handleSetProductCustomizationCount,
    handleSetProductData,
    handleIncreamentClick,
    handleDecreamentClick,
  } = useCartHandlers();

  const hasVariant = props.data?.variants_count > 0;
  const itemIndexInCart = cartData?.items?.findIndex(
    (el) => (el.id || el.item_id) === data?.id
  );

  const hasScheduler = !!props.data?.item_schedule;

  useEffect(() => {
    if (data && data?.is_active !== ITEM_ACTIVE_STATUS.HIDDEN) {
      const percent = getDiscountPercentage(data?.price, data?.discounted_price);
      setDiscountPercent(percent ? percent : 0);
      scrollItemAndRemoveDetails(data, cardRef);
    }
  }, [data]);

  const noTags = (function () {
    switch (noTagsReadState) {
      case 'fromProp':
        return noTagsFromProps;
      case 'fromLength':
        return !data?.tags?.length;
      default:
        return noTagsFromProps;
    }
  })();

  const cardRef = useRef(null);

  const product_aspect_ratio =
    theme && theme.product_aspect_ratio !== 0
      ? parseFloat(theme?.product_aspect_ratio / 100 || 0).toFixed(3)
      : 1;
  if (skeleton) {
    return (
      <CardContainer imageUrl={'skeleton'}>
        {skeletonImage && (
          <img
            src={skeletonImage}
            style={{
              height:
                deviceWidth < 767
                  ? data?.thumbnail_url
                    ? `calc(((100vw - 60px) / 2)/${
                        props.product_aspect_ratio !== 0 ? props.product_aspect_ratio : 1
                      });`
                    : 'auto'
                  : data?.thumbnail_url
                    ? `calc(200px/${product_aspect_ratio !== 0 ? product_aspect_ratio : 1})`
                    : '180px',
            }}
            loading="lazy"
          />
        )}
        {!skeletonImage && (
          <CardBody>
            <CardImage>
              <div
                style={{ width: '100%', height: '100%' }}
                className="skeleton br3"
              ></div>
            </CardImage>
            <CardMain imageUrl="skeleton" padding={'4px 0 0'}>
              <CardData>
                {!noTags && (
                  <CardTags padding="8px 0 0px">
                    <CardTag className="skeleton">Tag Name</CardTag>
                    <CardTag className="skeleton">Tag</CardTag>
                  </CardTags>
                )}
                <CardTitle categoryCard={showCategoryCard}>
                  <CardDescriptionMock className="skeleton br3">
                    Loading Product Name Mock
                  </CardDescriptionMock>
                  <CardDescriptionMock className="skeleton br3">
                    Product Name
                  </CardDescriptionMock>
                </CardTitle>
                {!showCategoryCard && (
                  <CardPrice>
                    <AmountDiscounted
                      fontColor={
                        props.builderOverrideStyles?.textColor ||
                        theme?.colors?.primary_color
                      }
                      className="skeleton br3"
                    >
                      ₹ 0
                    </AmountDiscounted>
                  </CardPrice>
                )}
              </CardData>
            </CardMain>
            <div className={premium === 0 && 'ph2 pb2'}>
              <CardDescription>
                <CardDescriptionMock className="skeleton br3">
                  Description Demo
                </CardDescriptionMock>
                <CardDescriptionMock className="skeleton br3">
                  Description Demo Content
                </CardDescriptionMock>
                <CardDescriptionMock className="skeleton br3">
                  Description
                </CardDescriptionMock>
              </CardDescription>
            </div>
          </CardBody>
        )}
      </CardContainer>
    );
  }

  if (data?.is_active === ITEM_ACTIVE_STATUS.HIDDEN) return null;

  const handleAddRemoveItem = (type) => {
    dispatch(
      pushEventsLog({
        event_name: 'Cx_ATC',
        data: {
          store_id: storeInfo?.store_id,
          domain: storeInfo?.store_info?.domain,
          page: window.location.href,
          device: deviceWidth <= MAX_MOBILE_WIDTH ? 'Mobile' : 'Desktop',
          cx_number: userData?.data?.phone,
        },
      })
    );
    const productItem = cartData.items[itemIndexInCart];

    // managed_inventory !== 0. available_quantity !== 0.
    // don't proceed to control flow if available_quantity is 0
    if (type === 'inc' && itemQuantityValueComparison(productItem)) {
      toastNotifyInfo('No more units available for purchase');
      return;
    }
    /** Check if variant present in this item */
    if (hasVariant) {
      dispatch(saveProductLoaderId(props.data?.id));
      dispatch(savePDPData(props?.data));
      /** check if item exist in cart */
      if (itemIndexInCart !== -1) {
        switch (type) {
          case 'inc':
            dispatch(setVariantModalVisibility(true));
            break;
          case 'dec':
            dispatch(setSelectedVariantModalVisibility(true));
            break;
        }
      } else {
        /** get the details item for this item id */
        dispatch(
          fetchCatalogItemById(
            { store_id: store_id, item_id: props.data?.id },
            true,
            (data) => {
              handleSetProductData(data);
            }
          )
        );
      }
    } else {
      if (data?.product_personalization) {
        handleSetProductCustomizationCount(data);
        dispatch(savePDPData(data));
        handleSetProductData(data);
        switch (type) {
          case 'add':
            dispatch(openProductPersonalizationPopUp());
            break;
          case 'inc':
            handleIncreamentClick(type, data);
            break;
          case 'dec':
            handleDecreamentClick(type, data);
            break;
          default:
            break;
        }
      } else {
        const newState = mutateItemObjectWithCartData(type, data, cartData);
        dispatch(saveCart(newState));
      }
    }
  };

  // function to remove all anchor tags from string
  function removeTagsAndReturn(str) {
    if (IS_SERVER) return str;
    const tmp = document.createElement('div');
    // add string to created div as html
    tmp.innerHTML = str;
    // get all anchor tags added to created element
    const anchor_tag_collection = tmp.getElementsByTagName('a');
    // iterate over all the tags and remove them from the HTML collection
    if (anchor_tag_collection.length) {
      // convert HTML collection to array
      Array.from(anchor_tag_collection).forEach(function (tag) {
        // remove the tags
        if (tag) {
          tag.parentNode.removeChild(tag);
        }
      });
    }
    return tmp.textContent || tmp.innerText || '';
  }

  const hasRating =
    !crrFeatureStatus?.isLocked &&
    reviewSettings?.status &&
    data?.store_item_review_rating?.review_rating &&
    data?.store_item_review_rating?.review_rating[0]?.avg_rating;

  const cardImageUrl = theme?.custom_theme_flag ? data?.image_url : data?.thumbnail_url;
  const compressedImages =
    Array.isArray(data?.images) &&
    data?.images.length > 0 &&
    data?.images[0]?.compressed_images;

  const renderCard = () => {
    return (
      <CardImage
        imageUrl={data?.thumbnail_url}
        product_aspect_ratio={product_aspect_ratio}
      >
        {!showCategoryCard && discountPercent > 0 && !isB2bStore && (
          <CardDiscount imageUrl={data?.thumbnail_url}>
            {`${discountPercent}% OFF`}
          </CardDiscount>
        )}
        {data?.thumbnail_url && (
          <img
            property="image"
            loading="lazy"
            src={getImageUrlFromCompressedImages(compressedImages, cardImageUrl, {
              maxImgSize: getMaxImgSizeForComponent(componentTypeEnums.CARD),
            })}
            alt={`Image ${data?.name || 'available Items'}`}
          />
        )}
      </CardImage>
    );
  };

  return oosStatus === 0 || data?.available ? (
    <CardContainer
      cardWidth={cardWidth}
      isAvailable={data?.available}
      isB2bStore={isB2bStore}
      reactSlick={props.reactSlick}
      categoryCard={showCategoryCard}
      imageUrl={data?.thumbnail_url}
      vocab="http://schema.org/"
      typeof="Product"
      // style={{
      //   boxShadow:
      //     premium === 0 && theme.is_demo === 0 ? '0 2px 6px #4a4a4a' : '',
      // }}
      onClick={(e) => {
        const func = props?.onItemClick || onItemClick;
        func(data);
      }}
      ref={cardRef}
    >
      {data?.brand_name && (
        <span rel="schema:brand">
          <span typeof="schema:Brand">
            <meta property="schema:name" content={data?.brand_name} />
          </span>
        </span>
      )}
      {data?.barcode && <meta property="schema:gtin" content={data?.barcode} />}
      <meta property="schema:sku" content={data?.sku_id || data?.id} />
      <meta property="schema:productID" content={data?.id} />
      <CardBody>
        {canShowPDPSystem(theme?.theme_class) ? (
          <Link href={getLink(data)}>{renderCard()}</Link>
        ) : (
          renderCard()
        )}
        {!noTags && data?.tags?.length > 0 && (
          <CardTags padding={premium === 0 ? '4px 6px' : '8px 0 0px'}>
            {data?.tags?.map(
              (tag) =>
                tag.is_active !== 0 && (
                  <CardTag backgroundColor={tag.tag_color} key={tag.id}>
                    {tag.name}
                  </CardTag>
                )
            )}
          </CardTags>
        )}
        <CardMain
          imageUrl={data?.thumbnail_url}
          padding={
            theme?.custom_theme_flag ? '12px 0 0' : premium === 0 ? '4px 6px' : '4px 0 0'
          }
        >
          <CardData>
            <CardTitle
              fontColor={props.builderOverrideStyles?.textColor || '#000'}
              categoryCard={showCategoryCard}
              property="name"
            >
              {data?.name || 'Available Items'}
            </CardTitle>
            {!!data?.available && checkForLimitedQuantity(data) && !isB2bStore && (
              <CardInventory className="highlighter-red-color">
                Limited Stock Left
              </CardInventory>
            )}
            {!showCategoryCard && (
              <div rel="schema:offers">
                <CardPrice typeof="schema:Offer">
                  <meta
                    property="schema:availability"
                    content={`https://schema.org/${
                      data?.available ? 'InStock' : 'OutOfStock'
                    }`}
                  />

                  {isB2bStore ? (
                    <AmountDiscounted
                      fontColor={
                        props.builderOverrideStyles?.textColor ||
                        theme?.colors?.primary_color
                      }
                    >
                      {data?.b2b_pricing_info?.price_range || 'Price on Request'}
                    </AmountDiscounted>
                  ) : (
                    <>
                      <AmountDiscounted
                        fontColor={
                          props.builderOverrideStyles?.textColor ||
                          theme?.colors?.primary_color
                        }
                      >
                        <span property="schema:priceCurrency" content="INR">
                          ₹
                        </span>
                        <span property="schema:price">{data?.discounted_price}</span>
                        <meta
                          property="schema:priceValidUntil"
                          content={moment(new Date()).add(1, 'year').format('YYYY-MM-DD')}
                        />
                      </AmountDiscounted>
                      {data?.discounted_price !== data?.price && (
                        <AmountActual
                          fontColor={
                            props.builderOverrideStyles?.textColor
                              ? hexToRgba(props.builderOverrideStyles?.textColor, 0.7)
                              : 'rgba(0, 0, 0, 0.3)'
                          }
                        >
                          ₹{data?.price}
                        </AmountActual>
                      )}
                    </>
                  )}
                </CardPrice>
              </div>
            )}
          </CardData>
          {services?.store_flag !== 0 &&
            !!data?.available &&
            props.showButton &&
            !isB2bStore && (
              <ButtonContainer>
                <Button
                  disableAddition={itemQuantityValueComparison(
                    cartData.items[itemIndexInCart]
                  )}
                  hasVariant={hasVariant}
                  fontSize="14"
                  onChange={handleAddRemoveItem}
                  quantity={
                    itemIndexInCart >= 0 ? cartData.items[itemIndexInCart].quantity : 0
                  }
                  hasScheduler={hasScheduler}
                />
                {hasVariant && (
                  <div className="variant--count-icontext">
                    <img src="/assets/images/optionsMultiple.png" />
                    {props.data?.variants_count} options
                  </div>
                )}
              </ButtonContainer>
            )}
          {!showCategoryCard && !imageUrl && discountPercent > 0 && !isB2bStore && (
            <CardDiscount>{`${discountPercent}% OFF`}</CardDiscount>
          )}
        </CardMain>
        {!hasRating && (
          <OfferAvailableTag productInfo={data} customClasses="tw-mt-[8px]" />
        )}
        <div className={hasRating ? 'pv8px' : ''}>
          <RatingBox
            isVisible={hasRating}
            rating={
              data?.store_item_review_rating?.review_rating &&
              data?.store_item_review_rating?.review_rating[0]?.avg_rating
            }
            ratingsCount={
              (data?.store_item_review_rating?.review_rating &&
                data?.store_item_review_rating?.review_rating[0]?.ratings_count) ||
              data?.store_item_review_rating?.ratings_count
            }
            productInfo={data}
            ratingCountColor={props.builderOverrideStyles?.textColor}
          />
        </div>
        <div className={premium === 0 && 'ph2 pb2'} property="description">
          {!data?.available && !isB2bStore ? (
            <CardDescription
              fontColor={
                props.builderOverrideStyles?.textColor
                  ? hexToRgba(props.builderOverrideStyles?.textColor, 0.6)
                  : '#9c9c9c'
              }
            >
              Out of stock
            </CardDescription>
          ) : (
            !showCategoryCard &&
            data?.description && (
              <CardDescription
                fontColor={
                  props.builderOverrideStyles?.textColor
                    ? hexToRgba(props.builderOverrideStyles?.textColor, 0.6)
                    : '#9c9c9c'
                }
                isAvailable={!!data?.available}
              >
                {removeTagsAndReturn(data?.description)}
              </CardDescription>
            )
          )}
        </div>
      </CardBody>
    </CardContainer>
  ) : null;
};
