/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import CustomizedButton from '@/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import classNames from 'classnames';
import { useSSRSelector } from 'src/redux';
import { checkProductPersonalizationExist } from '@/containers/ProductDescription/Utils/common';
import { useCartHandlers } from '@/hooks/useCartHandler';
import { MinusIcon } from '@/assets/svgExports/MinusIcon';
import { PlusIcon } from '@/assets/svgExports/PlusIcon';
import { itemIndexFromCart } from 'src/utils/itemIndexFromCart';
import { getRoute, PATH } from 'src/utils/routes';
import Router from 'next/router';
import { BUTTON_ACTION, getCTAIcon, getTextThickness } from '../utils';
import { itemQuantityValueComparison } from 'src/utils/itemQuantityValueComparison';
import { useDispatch } from 'react-redux';
import {
  fetchCatalogItemById,
  pushEventsLog,
  saveCart,
  savePDPData,
  saveProductLoaderId,
  setSelectedVariantModalVisibility,
  setVariantModalVisibility,
} from 'src/redux/actions';
import { mutateItemObjectWithCartData } from 'src/utils/mutateItemObjectWithCartData';
import { getButtonVariantsData } from '../../Carousel/utils';
import { BUTTON_TYPE } from 'src/components/WidgetMaker/utils/buttonConstant';
import buttonAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentButtonAnimationStyle.module.scss';
import { cloneDeep } from 'lodash';

export function AddToCartButton({
  isMobile,
  productData,
  config,
  isOverlay = false,
  snackbarMethods,
  overrideStyles = { textColor: '' },
}) {
  const dispatch = useDispatch();
  const { storeInfo, userData, cartData } = useSSRSelector((state) => ({
    storeInfo: state.storeReducer.store,
    userData: state.userReducer,
    cartData: state.cartReducer,
  }));
  const [hoverColors, setHoverColors] = useState(null);

  const {
    handleIncreamentClick,
    handleDecreamentClick,
    handleSetProductData,
    handleSetProductCustomizationCount,
    onPersonalizeProductClick,
  } = useCartHandlers();

  // In below the image case
  const disableWholeButton = !productData?.available;
  const overrideTextColor = overrideStyles?.textColor;

  const button = useMemo(() => {
    return config.buttonConfig
      ?.filter((btn) => btn.isShow)
      ?.map?.((btnData) => {
        const buttonData = cloneDeep(btnData);
        const isBtnTextual = buttonData.type === BUTTON_TYPE.TEXTUAL;
        const isBtnOutline = buttonData.type === BUTTON_TYPE.OUTLINE;
        if (overrideTextColor) {
          if (isBtnOutline) {
            buttonData.btnStyleConfig.textColor = overrideTextColor;
            buttonData.btnStyleConfig.backgroundColor = overrideTextColor;
          }
          if (isBtnTextual) buttonData.btnStyleConfig.textColor = overrideTextColor;
        }
        return buttonData;
      });
  }, [config.buttonConfig, overrideTextColor]);

  if (!productData?.available && isOverlay) {
    return null;
  }

  const isProductPersonalizationAvailable = checkProductPersonalizationExist(
    storeInfo?.services?.store_type_flag,
    productData?.product_personalization
  );
  const itemIndexInCart = itemIndexFromCart(cartData, productData);
  const isItemInCart = itemIndexInCart >= 0;
  const itemInCart = cartData?.items[itemIndexInCart];
  const itemQuantity = itemInCart?.quantity;
  const isIconBasedCTA = ['icon', 'iconFrontOfProductName'].includes(config?.buttonType);
  const isFullWidthCTA = !isIconBasedCTA;
  const shouldWholeButtonClick = !isItemInCart || isIconBasedCTA;
  const IconComponent = getCTAIcon(config, isIconBasedCTA);
  const hasVariant = productData?.variants_count > 0;
  const disableAddition = itemQuantityValueComparison(itemInCart);
  // const wasItemAddedButThenItsVariantAdded =
  //   isItemInCart && itemQuantity && !productData?.available_quantity;

  /**
   * This function is responsible to perform 3 tasks:
   * 1. Set the active product data in personalisedProduct state of catalogReducer
   * 2. Set the product customization config ( image count, text count etc ) in personalisedProduct state of catalogReducer
   * 3. Open the product personalization popup
   */
  function openProductPersonalizationPopUp() {
    handleSetProductData(productData);
    handleSetProductCustomizationCount(productData);
    onPersonalizeProductClick();
  }

  const textThicknessStyle = getTextThickness(config?.buttonTextThicness);

  function _RenderIconComponent(buttonData) {
    return (
      IconComponent && (
        <span
          className={classNames(
            'tw-flex tw-cursor-pointer tw-items-center tw-justify-center',
            isMobile ? 'tw-h-[16px] tw-w-[16px]' : 'tw-h-[18px] tw-w-[18px]'
          )}
        >
          <IconComponent
            height={isMobile ? '16' : '18'}
            width={isMobile ? '16' : '18'}
            iconColor={hoverColors?.text || buttonData?.btnStyleConfig?.textColor}
          />
        </span>
      )
    );
  }

  const goToCart = async (cart) => {
    // await saveAdditionalRemark(cart);
    Router.push(getRoute(PATH.CART, storeInfo?.store_info?.domain));
  };

  const handleAddRemoveItem = async (e, type, isNewAddition = false) => {
    //  if (disableWholeButton) {
    //    return;
    //  }
    e.stopPropagation?.();
    e.preventDefault?.();

    if (isNewAddition && isItemInCart) return;
    // if (type === BUTTON_ACTION.INCREMENT && disableAddition) return;

    dispatch(
      pushEventsLog({
        event_name: 'Cx_ATC',
        data: {
          store_id: storeInfo?.store_id,
          domain: storeInfo?.store_info?.domain,
          page: window.location.href,
          device: isMobile ? 'Mobile' : 'Desktop',
          cx_number: userData?.data?.phone,
        },
      })
    );
    const productItem = cartData.items[itemIndexInCart];

    // managed_inventory !== 0. available_quantity !== 0.
    // don't proceed to control flow if available_quantity is 0
    if (type === BUTTON_ACTION.INCREMENT && itemQuantityValueComparison(itemInCart)) {
      // toastNotifyInfo('No more units available of this item');
      snackbarMethods?.openSnackbar({
        message: 'No more units available of this item',
        vertical: 'bottom',
        horizontal: 'center',
        type: '',
      });
      return;
    }
    snackbarMethods?.hideSnackbar();

    /** Check if variant present in this item */
    if (hasVariant) {
      dispatch(saveProductLoaderId(productData?.id));
      dispatch(savePDPData(productData));
      /** check if item exist in cart */
      if (itemIndexInCart !== -1) {
        switch (type) {
          case BUTTON_ACTION.INCREMENT:
            dispatch(setVariantModalVisibility(true));
            break;
          case BUTTON_ACTION.DECREMENT:
            dispatch(setSelectedVariantModalVisibility(true));
            break;
        }
      } else {
        /** get the details item for this item id */
        dispatch(
          fetchCatalogItemById(
            { store_id: storeInfo?.store_id, item_id: productData?.id },
            true,
            (data) => {
              handleSetProductData(data);
            }
          )
        );
      }
    } else {
      if (isProductPersonalizationAvailable && productData?.product_personalization) {
        handleSetProductCustomizationCount(productData);
        dispatch(savePDPData(productData));
        handleSetProductData(productData);
        switch (type) {
          case BUTTON_ACTION.ADD:
            dispatch(openProductPersonalizationPopUp());
            break;
          case BUTTON_ACTION.INCREMENT:
            handleIncreamentClick(type, productData);
            break;
          case BUTTON_ACTION.DECREMENT:
            handleDecreamentClick(type, productData);
            break;
          default:
            break;
        }
      } else {
        const newState = mutateItemObjectWithCartData(type, productData, cartData);
        dispatch(saveCart(newState));
      }
    }
  };

  const addItemToCart = (e) => {
    if (shouldWholeButtonClick && !disableWholeButton)
      handleAddRemoveItem(
        e,
        !isItemInCart ? BUTTON_ACTION.ADD : BUTTON_ACTION.INCREMENT,
        !isItemInCart
      );
  };

  return button?.map((buttonData, btnIndex) => {
    // const overlayTextStyles: any = getOverlayTextStyles(cardFrameConfig, buttonData);
    const buttonVariant = getButtonVariantsData(buttonData, false);
    const selectedButtonType = buttonVariant[buttonData.type];
    const isBtnSolid = buttonData.type === BUTTON_TYPE.SOLID;
    const isBtnTextual = buttonData.type === BUTTON_TYPE.TEXTUAL;

    return (
      <CustomizedButton
        disabled={disableWholeButton}
        key={btnIndex}
        btnType={buttonData.type}
        onClick={addItemToCart}
        buttonData={buttonData}
        color={isBtnTextual && overrideTextColor}
        selectedButtonData={selectedButtonType}
        variant={selectedButtonType.variant}
        disableHoverAnimation={isItemInCart || isMobile}
        useReversedColors={isItemInCart || (isBtnSolid && !!overrideTextColor)}
        className={classNames(
          'addToCtaButtonForProduct !tw-pointer-events-auto !tw-max-h-[44px] !tw-min-w-[auto] tw-cursor-pointer !tw-gap-[8.5px] !tw-font-medium',
          isFullWidthCTA &&
            (isBtnTextual
              ? shouldWholeButtonClick
                ? '!tw-w-max'
                : '!tw-w-[81px]'
              : '!tw-w-full'),
          !isIconBasedCTA &&
            !isBtnTextual &&
            (isMobile ? 'tw-py-[10px] !tw-text-[13px]' : 'tw-py-[14px] !tw-text-[14px]'),
          isIconBasedCTA
            ? isMobile
              ? '!tw-p-[7px]'
              : '!tw-p-[9.5px]'
            : !isBtnTextual
              ? '!tw-px-[24px]'
              : '!tw-px-0 tw-py-0',
          isIconBasedCTA &&
            (isMobile ? '!tw-h-[33px] !tw-w-[33px]' : '!tw-h-[36px] !tw-w-[36px]'),
          isIconBasedCTA ? 'tw-cursor-pointer' : 'tw-cursor-default',
          isOverlay && '!tw-shadow-[0px_2px_2px_0px_#00000040]',
          isIconBasedCTA &&
            isMobile &&
            !disableWholeButton &&
            '!tw-border-none tw-shadow-[0px_2px_2px_0px_#00000040]',
          // isBtnSolid && !isIconBasedCTA ? ' !tw-border-none ' : '',
          // disable animations
          !buttonData?.buttonAnimationConfig?.classKey || isIconBasedCTA
            ? '[&_p]:before:!tw-hidden'
            : !disableWholeButton &&
                !isMobile &&
                buttonAnimationStyle[buttonData?.buttonAnimationConfig?.classKey],
          disableWholeButton && '!tw-opacity-50',
          !shouldWholeButtonClick && '[&_p]:before:!tw-hidden',
          isIconBasedCTA && isItemInCart && (!isMobile ? '!tw-w-[84px]' : '!tw-w-[70px]')
        )}
        textWrapperClass={classNames(
          '!tw-w-full !tw-relative !tw-flex !tw-justify-center',
          isBtnTextual && '!tw-mb-[2.5px]',
          textThicknessStyle.className
        )}
        getHoverColor={(btnColors) => setHoverColors(btnColors)}
      >
        {!isItemInCart ? (
          !isIconBasedCTA ? (
            <span className="tw-inline-flex tw-w-[100%] tw-items-center tw-justify-center tw-gap-[8px]">
              {_RenderIconComponent(buttonData)}
              <span>{buttonData?.text}</span>
            </span>
          ) : (
            <span
              className={classNames(
                'tw-flex  tw-cursor-pointer tw-items-center tw-justify-center',
                isMobile ? 'tw-h-[16px] tw-w-[16px]' : 'tw-h-[18px] tw-w-[18px]'
              )}
            >
              {!isItemInCart ? _RenderIconComponent(buttonData) : itemQuantity}
            </span>
          )
        ) : (
          <span className="tw-inline-flex tw-w-[100%] tw-items-center tw-justify-center">
            <span
              onClick={(e) => handleAddRemoveItem(e, BUTTON_ACTION.DECREMENT)}
              className={classNames(
                'tw-flex tw-h-[100%] tw-cursor-pointer tw-items-center tw-justify-center',
                'tw-absolute tw-left-0 tw-top-1/2 -tw-translate-y-1/2'
              )}
            >
              <MinusIcon
                iconHeight={16}
                iconWidth={16}
                iconColor={hoverColors?.text || buttonData?.btnStyleConfig?.textColor}
              />
            </span>
            <span>{cartData.items[itemIndexInCart].quantity || 0}</span>
            <span
              onClick={(e) => handleAddRemoveItem(e, BUTTON_ACTION.INCREMENT)}
              className={classNames(
                'tw-flex tw-h-[100%] tw-cursor-pointer tw-items-center tw-justify-center',
                'tw-absolute tw-right-0 tw-top-1/2 -tw-translate-y-1/2'
              )}
            >
              <PlusIcon
                iconHeight={16}
                iconWidth={16}
                iconColor={hoverColors?.text || buttonData?.btnStyleConfig?.textColor}
                style={{ opacity: !disableAddition ? 1 : 0.5 }}
              />
            </span>
          </span>
        )}
      </CustomizedButton>
    );
  });
}
